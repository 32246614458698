/* eslint-disable no-useless-escape */
import React, { Component } from "react";

import "./bookChef.css";
import { Modal, Button } from "react-bootstrap";

import MultiSelectReact from "multi-select-react";

// import MultiSelect from "@kenshooui/react-multi-select";
// import "@kenshooui/react-multi-select/dist/style.css";
//import Multiselect from 'multiselect-react-dropdown';
// import { optionValues } from "./optionValue";
// import GooglePayButton from "@google-pay/button-react";
// import { Col, Form } from "react-bootstrap";
// import data from './data.json';

import newdata from "./newdata.json";
// import whatsApp from './whatsApp.js';

// import 'bootstrap'
import images from "./images/logo.jpg";
import imageB from "./images/B.png";
import imageM from "./images/M.png";
import imageD from "./images/D.png";
import imageG from "./images/G.png";
import clickMeImage from "./images/clickme.gif";
import box from "./images/box.png";
import axios from "axios";

import { runInThisContext } from "vm";

export default class BookChef extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen1: false,
      isOpen2: false,

      appetizer: [],
      boolean: true,
      data: [newdata.Sheet1],
      mainCourse: [],
      date: "",
      dessert: [],
      breadRice: [],
      people: 0,
      name: "",
      mobileno: "",
      email: "",
      dessertClassname: "caterNinja_add_dessert_button",
      showDessert: false,
      totalMainCoursePrice: 0,
      totalAppeticerPrice: 0,
      totalDessertPrice: 0,
      totalBreadRicePrice: 0,
      // namer: '',
      // emailr: '',
      // mobilenor: '',
      sent: false,
      msg: false,
      loader: false,
      buffet: 0,
      changedValue: true,
      fruit: [],
      err: true,
    };
    this.handleChangeV = this.handleChangeV.bind(this);
    // this.renderGroupByOptions = this.renderGroupByOptions.bind(this);
    // this.change = this.change.bind(this);
  }

  handleChangeV(e) {
    console.log("Fruit Selected!!");
    this.setState({ fruit: e.target.value });
  }

  componentDidUpdate(_, prevState) {
    if (prevState.people !== this.state.people) {
      this.calculate();
      // call any side-effect here
    }
  }

  refreshChange(e) {
    const mainCoursePriceArr = this.state.mainCourse.filter((item) => {
      return item.value === true;
    });

    const appetizerPriceArr = this.state.appetizer.filter((item) => {
      return item.value === true;
    });

    const dessertPriceArr = this.state.dessert.filter((item) => {
      return item.value === true;
    });

    const breadRicePriceArr = this.state.breadRice.filter((item) => {
      return item.value === true;
    });
    // this.setState({
    //     grandTotal: Math.round(grandT)

    // })
    this.setState({
      appetizer: appetizerPriceArr.map((item) => {
        return (item.value = false);
      }),
      mainCourse: mainCoursePriceArr.map((item) => {
        return (item.value = false);
      }),
      dessert: dessertPriceArr.map((item) => {
        return (item.value = false);
      }),
      breadRice: breadRicePriceArr.map((item) => {
        return (item.value = false);
      }),
      totalMainCoursePrice: 0,
      totalAppeticerPrice: 0,
      totalDessertPrice: 0,
      totalBreadRicePrice: 0,
      changedValue: false,
      mealtype: "",
    });
    // this.setState({
    //     changedValue:false,
    //     mealtype:""
    // })
    // this.change(e)
    this.calculate();
    setTimeout(() => {
      this.setState({
        changedValue: true,
      });
    }, 10);
  }

  // handleChangeV = valueA => {
  //     this.setState({ valueA });
  //     console.log("hshhsdjhs")
  // };

  onChangeB(e) {
    this.setState({
      buffet: e.target.value,
    });
    console.log(this.state.buffet);
  }

  componentDidMount() {
    const x = document.referrer;

    if (sessionStorage.getItem("first_url") === null) {
      const catch_url = sessionStorage.setItem("first_url", "x");
      console.log(catch_url);
    } else {
      let url_value = sessionStorage.getItem("first_url");
      console.log(url_value);
    }

    // this.setState({
    //   url: url_value,
    // });

    var d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    this.setState({
      todaysDate: [year, month, day].join("-"),
      data: this.state.data[0].filter((element) => {
        if (element.label.includes("price")) {
          return element.label;
        } else if (element.quantity === "kg") {
          return (element.label = `${element.label}`);
        }
        return (element.label = `${element.label}`);
      }),
    });
  }

  openModal = () => this.setState({ isOpen1: true });

  closeModal = () => {
    window.location.reload();
    this.setState({
      isOpen1: false,
      isOpen2: false,

      appetizer: [],
      boolean: true,
      data: [newdata.Sheet1],

      mainCourse: [],
      date: "",
      dessert: [],

      totalMainCoursePrice: 0,
      totalAppeticerPrice: 0,
      totalDessertPrice: 0,

      occasion: "",
      people: 0,
      meal: "",
      cuisine: "",
      preference: "",
      mealtype: "",
    });
  };

  //   calculate() {
  //     let peoples = this.state.people;

  //     const mainCoursePriceArr = this.state.mainCourse.filter((item) => {
  //       return item.value === true;
  //     });

  //     const appetizerPriceArr = this.state.appetizer.filter((item) => {
  //       return item.value === true;
  //     });

  //     const dessertPriceArr = this.state.dessert.filter((item) => {
  //       return item.value === true;
  //     });

  //     const breadRicePriceArr = this.state.breadRice.filter((item) => {
  //       return item.value === true;
  //     });

  //     // if(appetizerPriceArr.length>5){
  //     //   this.yourDetail();
  //     // }else{
  //     //     alert("okok")
  //     // }
  //     console.log("app");
  //     console.log(appetizerPriceArr);
  //     console.log("main");
  //     console.log(mainCoursePriceArr);
  //     console.log("dess");
  //     console.log(dessertPriceArr);
  //     console.log("bread");
  //     console.log(breadRicePriceArr);

  //     let mainCoursePrice = mainCoursePriceArr.reduce((acc, item) => {
  //       if (item.quantity === "kg") {
  //         if (mainCoursePriceArr.length === 1) {
  //           return acc + item.price * peoples * 0.15 * 1.05;
  //         }
  //         return acc + item.price * peoples * 0.1 * 1.05;
  //       }
  //       if (item.name === "Pooris") {
  //         return acc + (item.price / 12) * peoples * 3 * 1.05;
  //       }
  //       return acc + (item.price / 12) * peoples * 2 * 1.05;
  //     }, 0);

  //     let breadRicePrice = breadRicePriceArr.reduce((acc, item) => {
  //       if (item.quantity === "kg") {
  //         if (mainCoursePriceArr.length === 1) {
  //           return acc + item.price * peoples * 0.15 * 1.05;
  //         }
  //         return acc + item.price * peoples * 0.1 * 1.05;
  //       }
  //       if (item.name === "Pooris") {
  //         return acc + (item.price / 12) * peoples * 3 * 1.05;
  //       }
  //       return acc + (item.price / 12) * peoples * 2 * 1.05;
  //     }, 0);

  //     let appetizerPrice = appetizerPriceArr.reduce((accu, itema) => {
  //       if (itema.quantity === "kg") {
  //         // if(mainCoursePriceArr.length === 1){
  //         //     return accu + (itema.price * peoples * 0.150)
  //         // }
  //         return accu + itema.price * peoples * 0.1 * 1.05;
  //       }
  //       // if(itema.name === "Pooris"){
  //       //     return accu + ((itema.price)/12 * peoples * 3 )
  //       // }
  //       return accu + (itema.price / 12) * (peoples * 2 * 1.05);
  //     }, 0);

  //     let dessertPricee = dessertPriceArr.reduce((accum, itemd) => {
  //       if (itemd.quantity === "kg") {
  //         return accum + itemd.price * peoples * 0.075 * 1.05;
  //       }
  //       return accum + (itemd.price / 12) * peoples * 2 * 1.05;
  //     }, 0);

  //     console.log(this.state);

  //     let gst =
  //       ((this.state.totalAppeticerPrice +
  //         this.state.totalMainCoursePrice +
  //         this.state.totalDessertPrice +
  //         this.state.totalBreadRicePrice) *
  //         5) /
  //       100;
  //     let subTot =
  //       this.state.totalAppeticerPrice +
  //       this.state.totalMainCoursePrice +
  //       this.state.totalDessertPrice +
  //       this.state.totalBreadRicePrice;

  //     let grandT = subTot + gst;

  //     this.setState({
  //       totalMainCoursePrice: Math.round(mainCoursePrice),
  //       totalAppeticerPrice: Math.round(appetizerPrice),
  //       totalDessertPrice: Math.round(dessertPricee),
  //       totalBreadRicePrice: Math.round(breadRicePrice),
  //       totGst: Math.round(gst),
  //       subTotal: Math.round(subTot),
  //       grandTotal: Math.round(grandT),
  //     });
  //   }
  calculate() {
    let peoples = this.state.people;

    const mainCoursePriceArr = this.state.mainCourse.filter((item) => {
      return item.value === true;
    });

    const appetizerPriceArr = this.state.appetizer.filter((item) => {
      return item.value === true;
    });

    const dessertPriceArr = this.state.dessert.filter((item) => {
      return item.value === true;
    });

    const breadRicePriceArr = this.state.breadRice.filter((item) => {
      return item.value === true;
    });

    // if(appetizerPriceArr.length>5){
    //   this.yourDetail();
    // }else{
    //     alert("okok")
    // }
    console.log("app");
    console.log(appetizerPriceArr);
    console.log("main");
    console.log(mainCoursePriceArr);
    console.log("dess");
    console.log(dessertPriceArr);
    console.log("bread");
    console.log(breadRicePriceArr);

    // let mainCoursePrice = mainCoursePriceArr.reduce((acc, item) => {
    //   if (item.quantity === "kg") {
    //     if (mainCoursePriceArr.length === 1) {
    //       return acc + item.price * peoples * 0.15 * 1.05;
    //     }
    //     return acc + item.price * peoples * 0.1 * 1.05;
    //   }
    //   if (item.name === "Pooris") {
    //     return acc + (item.price / 12) * peoples * 3 * 1.05;
    //   }
    //   return acc + (item.price / 12) * peoples * 2 * 1.05;
    // }, 0);

    // let breadRicePrice = breadRicePriceArr.reduce((acc, item) => {
    //     if (item.quantity === "kg") {
    //         if (mainCoursePriceArr.length === 1) {
    //             return acc + (item.price * peoples * 0.150 * 1.05)
    //         }
    //         return acc + (item.price * peoples * 0.100 * 1.05)
    //     }
    //     if (item.name === "Pooris") {
    //         return acc + ((item.price) / 12 * peoples * 3 * 1.05)
    //     }
    //     return acc + ((item.price) / 12 * peoples * 2 * 1.05)
    // }, 0)
    let mainCoursePrice = mainCoursePriceArr.reduce((acc, item) => {
      if (item.quantity === "kg") {
        if (item.mealsubtype === "Heavy Snack") {
          // 100 gms per person "always"
          return acc + item.price * peoples * 0.1 * 1.05;
        } else if (item.mealsubtype === "Pasta") {
          if (mainCoursePriceArr.length === 1) {
            //200gm
            return acc + item.price * peoples * 0.2 * 1.05;
          } else {
            return acc + item.price * peoples * 0.15 * 1.05;
          }
        } else {
          // rest all MC 100gms
          //return acc + item.price * peoples * 0.1 * 1.05;
          if (item.preference === "nonveg") {
            // variable
            let highV = Math.max(item.price);
            if (item.price === highV) {
              return acc + highV * peoples * 0.15 * 1.05;
            } else {
              return acc + item.price * peoples * 0.1 * 1.05;
            }
          } else if (item.preference === "veg") {
            if (item.mealsubtype === ("Mains Paneer" || "Mains Thai")) {
              //150
              return acc + item.price * peoples * 0.15 * 1.05;
            } else {
              //100
              return acc + item.price * peoples * 0.1 * 1.05;
            }
          }
          return console.log("Exit");
        }
        // Main Course Logic HEAVY SNACK + pcs
      } else if (item.quantity === "pcs") {
        if (item.mealsubtype === "Heavy Snack") {
          // return one per person
          return acc + (item.price / 12) * peoples * 1 * 1.05;
        } else {
          return acc + (item.price / 12) * peoples * 2 * 1.05;
        }
      }
      return console.log("Exit");
    }, 0);

    let breadRicePrice = breadRicePriceArr.reduce((acc, item) => {
      // Bread + Rice (Column H + "kg" filter)

      //   if (mainCoursePriceArr.length === 1) {
      //     return acc + item.price * peoples * 0.15 * 1.05;
      //   }
      //   // MC> 0 Rice is 2 or more
      //   else
      if (item.quantity === "kg") {
        if (mainCoursePriceArr.length > 0 && breadRicePriceArr.length >= 2) {
          return acc + item.price * peoples * 0.15 * 1.05;
        }
        // // MC> 0 Rice is === 1
        else if (
          mainCoursePriceArr.length > 0 &&
          breadRicePriceArr.length === 1
        ) {
          // 200g
          return acc + item.price * peoples * 0.2 * 1.05;
        }
        // MC==0 Rice is 2 or more
        else if (
          mainCoursePriceArr.length === 0 &&
          breadRicePriceArr.length >= 2
        ) {
          return acc + item.price * peoples * 0.15 * 1.05;
        }
        // MC==0 Rice is 1
        else if (
          mainCoursePriceArr.length === 0 &&
          breadRicePriceArr.length === 1
        ) {
          // 300g
          return acc + item.price * peoples * 0.3 * 1.05;
        } else {
          return acc + item.price * peoples * 0.15 * 1.05;
        }
        // Bread + Rice (Column H + "pcs" filter)
      } else if (
        item.quantity === "pcs" &&
        item.name === ("Pooris" || "Luchi")
      ) {
        // If poori or luchi then 3 pcs
        return acc + (item.price / 12) * peoples * 3 * 1.05;
      } else {
        // else 2
        return acc + (item.price / 12) * peoples * 2 * 1.05;
      }
    }, 0);
    let dessertPricee = dessertPriceArr.reduce((accum, itemd) => {
      // if kg 0.075
      if (itemd.quantity === "kg") {
        return accum + itemd.price * peoples * 0.075 * 1.05;
      } else if (itemd.quantity === "pcs") {
        // if cuisine == conti & "pcs" filter
        if (itemd.cuisine === "Continental") {
          // 1 pc per person
          return accum + (itemd.price / 12) * peoples * 1 * 1.05;
        } else {
          // roundup (1.5 * per person)
          return accum + (itemd.price / 12) * peoples * 1.5 * 1.05;
        }
      } else {
        return accum + (itemd.price / 12) * peoples * 2 * 1.05;
      }
    }, 0);
    let appetizerPrice = appetizerPriceArr.reduce((accu, itema) => {
      if (itema.quantity === "kg") {
        // if(mainCoursePriceArr.length === 1){
        //     return accu + (itema.price * peoples * 0.150)
        // }
        return accu + itema.price * peoples * 0.1 * 1.05;
      }
      // if(itema.name === "Pooris"){
      //     return accu + ((itema.price)/12 * peoples * 3 )
      // }
      return accu + (itema.price / 12) * (peoples * 2 * 1.05);
    }, 0);

    // function starterQuantity(){
    //     if(this.state.mealtype===true && this.state.quantity==='pcs'){
    //         return peoples * 2
    //     }else{
    //         return peoples * 0.1
    //     }
    // }
    // let dessertPricee = dessertPriceArr.reduce((accum, itemd) => {
    //   if (itemd.quantity === "kg") {
    //     return accum + itemd.price * peoples * 0.075 * 1.05;
    //   }
    //   return accum + (itemd.price / 12) * peoples * 2 * 1.05;
    // }, 0);
    console.log(this.state);
    let gst =
      ((this.state.totalAppeticerPrice +
        this.state.totalMainCoursePrice +
        this.state.totalDessertPrice +
        this.state.totalBreadRicePrice) *
        5) /
      100;
    let subTot =
      this.state.totalAppeticerPrice +
      this.state.totalMainCoursePrice +
      this.state.totalDessertPrice +
      this.state.totalBreadRicePrice;

    let grandT = subTot + gst;

    this.setState({
      totalMainCoursePrice: Math.round(mainCoursePrice),
      totalAppeticerPrice: Math.round(appetizerPrice),
      totalDessertPrice: Math.round(dessertPricee),
      totalBreadRicePrice: Math.round(breadRicePrice),
      totGst: Math.round(gst),
      subTotal: Math.round(subTot),
      grandTotal: Math.round(grandT),
      // starterPlace: this.state.data[0].filter((element) => {
      //     if (element.quantity === "pcs") {
      //         return peoples * 2;
      //     } else {
      //         return peoples * 0.1;
      //     }
      // })
    });
  }
  showDiv() {
    document.getElementById("showDiv").style.display = "block";
  }
  // your function
  // checkDate = (e)=> {
  //     var dateD = document.getElementById("date");
  //       var  dateReg = /(0[1-9]|[12][0-9]|3[01])[\/](0[1-9]|1[012])[\/]201[4-9]|20[2-9][0-9]/;

  //     if (dateReg.test(dateD.value)) {
  //         document.getElementById("checkPrize").style.display = "block";
  //         document.getElementById('blockDiv').style.display = "block";
  //     } else {
  //         alert("Choose Date")
  //     }
  // }

  blockDiv() {
    var mum = document.getElementById("city_mumbai").checked;
    var blor = document.getElementById("city_bangalore").checked;
    var gur = document.getElementById("city_gurgaon").checked;
    var del = document.getElementById("city_delhi").checked;

    var veg = document.getElementById("veg").checked;
    var nonveg = document.getElementById("nonveg").checked;
    var people = document.getElementById("optionSelect").value;

    if ((mum || blor || gur || del) === false) {
      alert("Choose City");
      // document.getElementById('city_mumbai').scrollIntoView();
      // window.scroll(300, 0);
    } else if ((veg || nonveg) === false) {
      alert("Choose Meal Type");
    } else if (people === "") {
      alert("Choose Guest Count");
    } else {
      document.getElementById("checkPrize").style.display = "block";
      document.getElementById("blockDiv").style.display = "block";
      document
        .getElementById("blockDiv")
        .scrollIntoView({ behavior: "smooth" });
    }
  }

  yourDetail() {
    var em = document.getElementById("email").value;
    var numB = document.getElementById("mobno").value;
    document.getElementById("need").style.display = "inline-block";

    // var option = document.getElementsByClassName("selected-options-badges");
    // var selected = option.options[option.options.length] >5;

    if (
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(em) &&
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(numB)
    ) {
      document.getElementById("yourDetail").style.display = "block";
      this.setState({
        err: false,
      });
    } else {
      alert(" Enter valid Email & Mobile !!");
      this.setState({
        err: true,
      });
      // return (false)
    }
  }

  yourDetailB() {
    var em = document.getElementById("email").value;
    var numB = document.getElementById("mobno").value;
    document.getElementById("need").style.display = "inline-block";

    // var option = document.getElementsByClassName("selected-options-badges");
    // var selected = option.options[option.options.length] >5;

    if (
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(em) &&
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(numB)
    ) {
      document.getElementById("yourDetail").style.display = "block";
      this.formSubmitB();

      // this.setState({
      //     err:false
      // })
    } else {
      alert(" Enter valid Email & Mobile !!");
      // this.setState({
      //     err:true
      // })
      // return (false)
    }
  }

  hideCheckPrice() {
    var huhu = document.getElementById("yourDetail").style.display;
    if (huhu === "block") {
      document.getElementById("cckk").style.display = "none";
      document.getElementById("ckck").style.display = "none";
    }
  }
  occasion = (e) =>
    this.setState({
      occasion: e.target.value,
    });

  optionSelect() {
    var select = document.getElementById("optionSelect");

    for (var i = 10; i < 100; i++) {
      select.options[select.options.length] = new Option(i);
    }
    console.log(select);
  }

  change = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    if (this.state.city === "mumbai") {
      if (this.state.preference === "veg") {
        if (this.state.cuisine === "Indian & Continental") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "mumbai"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "mumbai"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.city === "mumbai" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental") &&
                  element.preference === "veg" &&
                  element.city === "mumbai"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "mumbai"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "mumbai"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "mumbai"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental") &&
                  element.preference === "veg" &&
                  element.city === "mumbai"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Indian & PanAsian") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "mumbai"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "mumbai"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "mumbai"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.preference === "veg" &&
                  element.city === "mumbai"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "mumbai"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "mumbai"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "mumbai"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.preference === "veg" &&
                  element.city === "mumbai"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Indian") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "mumbai"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "mumbai"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "mumbai"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Indian" &&
                  element.preference === "veg" &&
                  element.city === "mumbai"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "mumbai"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "mumbai"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "mumbai"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Indian" &&
                  element.preference === "veg" &&
                  element.city === "mumbai"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Continental") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "mumbai"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "mumbai"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "mumbai"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Continental" &&
                  element.preference === "veg" &&
                  element.city === "mumbai"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "mumbai"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "mumbai"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "mumbai"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Continental" &&
                  element.preference === "veg" &&
                  element.city === "mumbai"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "PanAsian") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "mumbai"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "mumbai"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "mumbai"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "PanAsian" &&
                  element.preference === "veg" &&
                  element.city === "mumbai"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "mumbai"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "mumbai"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "mumbai"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "PanAsian" &&
                  element.preference === "veg" &&
                  element.city === "mumbai"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Bengali") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "mumbai"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "mumbai"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Bengali") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "mumbai"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Bengali" &&
                  element.preference === "veg" &&
                  element.city === "mumbai"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Bengali") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "mumbai"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "mumbai"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "mumbai"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Bengali" &&
                  element.preference === "veg" &&
                  element.city === "mumbai"
                );
              }),
            });
          }
        } else {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "mumbai"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "mumbai"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "mumbai"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.city === "mumbai" &&
                  element.preference === "veg" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian")
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "mumbai"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "mumbai"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "mumbai"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.city === "mumbai" &&
                  element.preference === "veg" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian")
                );
              }),
            });
          }
        }
      } else if (this.state.preference === "nonveg") {
        if (this.state.cuisine === "Indian & Continental") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "mumbai"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "mumbai"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "mumbai"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.city === "mumbai" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental")
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "mumbai"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "mumbai"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "mumbai"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.city === "mumbai" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental")
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Indian & PanAsian") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "mumbai"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "mumbai"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "mumbai"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.city === "mumbai"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "mumbai"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "mumbai"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "mumbai"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.city === "mumbai"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Indian") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "mumbai"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "mumbai"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "mumbai"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Indian" &&
                  element.city === "mumbai"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "mumbai"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "mumbai"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "mumbai"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Indian" &&
                  element.city === "mumbai"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Continental") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "mumbai"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "mumbai"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "mumbai"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Continental" &&
                  element.city === "mumbai"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "mumbai"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "mumbai"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "mumbai"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Continental" &&
                  element.city === "mumbai"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "PanAsian") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "mumbai"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "mumbai"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "mumbai"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "PanAsian" &&
                  element.city === "mumbai"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "mumbai"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "mumbai"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "mumbai"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "PanAsian" &&
                  element.city === "mumbai"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Bengali") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "mumbai"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "mumbai"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Bengali") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "mumbai"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Bengali" &&
                  element.city === "mumbai"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Bengali") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "mumbai"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "mumbai"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "mumbai"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Bengali" &&
                  element.city === "mumbai"
                );
              }),
            });
          }
        } else {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "mumbai"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "mumbai"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "mumbai"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.city === "mumbai" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian")
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "mumbai"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "mumbai"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "mumbai"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.city === "mumbai" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian")
                );
              }),
            });
          }
        }
      } else {
        console.log("nonvegveg");
        // not required
      }

      // } else if (this.state.city === "Delhi") {
      //     console.log("ghhgg")
    } else if (this.state.city === "bangalore") {
      if (this.state.preference === "veg") {
        if (this.state.cuisine === "Indian & Continental") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.city === "bangalore" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental") &&
                  element.preference === "veg" &&
                  element.city === "bangalore"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental") &&
                  element.preference === "veg" &&
                  element.city === "bangalore"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Indian & PanAsian") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.preference === "veg" &&
                  element.city === "bangalore"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.preference === "veg" &&
                  element.city === "bangalore"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Indian") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Indian" &&
                  element.preference === "veg" &&
                  element.city === "bangalore"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Indian" &&
                  element.preference === "veg" &&
                  element.city === "bangalore"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "SouthIndian") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "SouthIndian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "SouthIndian" &&
                  element.preference === "veg" &&
                  element.city === "bangalore"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "SouthIndian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "SouthIndian" &&
                  element.preference === "veg" &&
                  element.city === "bangalore"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Continental") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Continental" &&
                  element.preference === "veg" &&
                  element.city === "bangalore"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Continental" &&
                  element.preference === "veg" &&
                  element.city === "bangalore"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "PanAsian") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              // removed cuisine filter
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "PanAsian" &&
                  element.preference === "veg" &&
                  element.city === "bangalore"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "PanAsian" &&
                  element.preference === "veg" &&
                  element.city === "bangalore"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Bengali") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Bengali") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Bengali" &&
                  element.preference === "veg" &&
                  element.city === "bangalore"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Bengali") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Bengali" &&
                  element.preference === "veg" &&
                  element.city === "bangalore"
                );
              }),
            });
          }
        } else {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.city === "bangalore" &&
                  element.preference === "veg" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian")
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.city === "bangalore" &&
                  element.preference === "veg" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian")
                );
              }),
            });
          }
        }
      } else if (this.state.preference === "nonveg") {
        if (this.state.cuisine === "Indian & Continental") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.city === "bangalore" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental")
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.city === "bangalore" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental")
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Indian & PanAsian") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.city === "bangalore"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.city === "bangalore"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Indian") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Indian" &&
                  element.city === "bangalore"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Indian" &&
                  element.city === "bangalore"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "SouthIndian") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "SouthIndian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "SouthIndian" &&
                  element.city === "bangalore"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "SouthIndian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "SouthIndian" &&
                  element.city === "bangalore"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Continental") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Continental" &&
                  element.city === "bangalore"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Continental" &&
                  element.city === "bangalore"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "PanAsian") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "PanAsian" &&
                  element.city === "bangalore"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "PanAsian" &&
                  element.city === "bangalore"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Bengali") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Bengali") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Bengali" &&
                  element.city === "bangalore"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Bengali") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Bengali" &&
                  element.city === "bangalore"
                );
              }),
            });
          }
        } else {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.city === "bangalore" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian")
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "bangalore"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "bangalore"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "bangalore"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.city === "bangalore" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian")
                );
              }),
            });
          }
        }
      } else {
        console.log("nonvegveg");
        // not required
      }

      // } else if (this.state.city === "Delhi") {
      //     console.log("ghhgg")
    } else if (this.state.city === "delhi") {
      if (this.state.preference === "veg") {
        if (this.state.cuisine === "Indian & Continental") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "delhi"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "delhi"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.city === "delhi" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental") &&
                  element.preference === "veg" &&
                  element.city === "delhi"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "delhi"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "delhi"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "delhi"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental") &&
                  element.preference === "veg" &&
                  element.city === "delhi"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Indian & PanAsian") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "delhi"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "delhi"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "delhi"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.preference === "veg" &&
                  element.city === "delhi"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "delhi"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "delhi"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "delhi"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.preference === "veg" &&
                  element.city === "delhi"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Indian") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "delhi"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "delhi"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "delhi"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Indian" &&
                  element.preference === "veg" &&
                  element.city === "delhi"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "delhi"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "delhi"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "delhi"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Indian" &&
                  element.preference === "veg" &&
                  element.city === "delhi"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Continental") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "delhi"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "delhi"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "delhi"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Continental" &&
                  element.preference === "veg" &&
                  element.city === "delhi"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "delhi"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "delhi"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "delhi"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Continental" &&
                  element.preference === "veg" &&
                  element.city === "delhi"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "PanAsian") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "delhi"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "delhi"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "delhi"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "PanAsian" &&
                  element.preference === "veg" &&
                  element.city === "delhi"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "delhi"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "delhi"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "delhi"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "PanAsian" &&
                  element.preference === "veg" &&
                  element.city === "delhi"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Bengali") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "delhi"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "delhi"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Bengali") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "delhi"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Bengali" &&
                  element.preference === "veg" &&
                  element.city === "delhi"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Bengali") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "delhi"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "delhi"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "delhi"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Bengali" &&
                  element.preference === "veg" &&
                  element.city === "delhi"
                );
              }),
            });
          }
        } else {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "delhi"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "delhi"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "delhi"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.city === "delhi" &&
                  element.preference === "veg" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian")
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "delhi"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "delhi"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "delhi"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.city === "delhi" &&
                  element.preference === "veg" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian")
                );
              }),
            });
          }
        }
      } else if (this.state.preference === "nonveg") {
        if (this.state.cuisine === "Indian & Continental") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "delhi"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "delhi"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "delhi"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.city === "delhi" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental")
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "delhi"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "delhi"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "delhi"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.city === "delhi" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental")
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Indian & PanAsian") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "delhi"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "delhi"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "delhi"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.city === "delhi"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "delhi"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "delhi"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "delhi"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.city === "delhi"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Indian") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "delhi"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "delhi"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "delhi"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Indian" &&
                  element.city === "delhi"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "delhi"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "delhi"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "delhi"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Indian" &&
                  element.city === "delhi"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Continental") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "delhi"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "delhi"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "delhi"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Continental" &&
                  element.city === "delhi"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "delhi"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "delhi"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "delhi"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Continental" &&
                  element.city === "delhi"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "PanAsian") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "delhi"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "delhi"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "delhi"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "PanAsian" &&
                  element.city === "delhi"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "delhi"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "delhi"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "delhi"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "PanAsian" &&
                  element.city === "delhi"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Bengali") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "delhi"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "delhi"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Bengali") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "delhi"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Bengali" &&
                  element.city === "delhi"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Bengali") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "delhi"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "delhi"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "delhi"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Bengali" &&
                  element.city === "delhi"
                );
              }),
            });
          }
        } else {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "delhi"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "delhi"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "delhi"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.city === "delhi" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian")
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "delhi"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" && element.city === "delhi"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "delhi"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.city === "delhi" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian")
                );
              }),
            });
          }
        }
      } else {
        console.log("nonvegveg");
        // not required
      }

      // } else if (this.state.city === "Delhi") {
      //     console.log("ghhgg")
    } else if (this.state.city === "gurgaon") {
      if (this.state.preference === "veg") {
        if (this.state.cuisine === "Indian & Continental") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "gurgaon"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "gurgaon"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.city === "gurgaon" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental") &&
                  element.preference === "veg" &&
                  element.city === "gurgaon"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "gurgaon"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "gurgaon"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "gurgaon"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental") &&
                  element.preference === "veg" &&
                  element.city === "gurgaon"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Indian & PanAsian") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "gurgaon"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "gurgaon"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "gurgaon"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.preference === "veg" &&
                  element.city === "gurgaon"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "gurgaon"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "gurgaon"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "gurgaon"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.preference === "veg" &&
                  element.city === "gurgaon"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Indian") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "gurgaon"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "gurgaon"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "gurgaon"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Indian" &&
                  element.preference === "veg" &&
                  element.city === "gurgaon"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "gurgaon"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "gurgaon"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "gurgaon"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Indian" &&
                  element.preference === "veg" &&
                  element.city === "gurgaon"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Continental") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "gurgaon"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "gurgaon"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "gurgaon"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Continental" &&
                  element.preference === "veg" &&
                  element.city === "gurgaon"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "gurgaon"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "gurgaon"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "gurgaon"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Continental" &&
                  element.preference === "veg" &&
                  element.city === "gurgaon"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "PanAsian") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "gurgaon"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "gurgaon"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "gurgaon"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "PanAsian" &&
                  element.preference === "veg" &&
                  element.city === "gurgaon"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "gurgaon"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "gurgaon"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "gurgaon"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "PanAsian" &&
                  element.preference === "veg" &&
                  element.city === "gurgaon"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Bengali") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "gurgaon"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "gurgaon"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Bengali") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "gurgaon"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Bengali" &&
                  element.preference === "veg" &&
                  element.city === "gurgaon"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Bengali") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "gurgaon"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "gurgaon"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "gurgaon"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Bengali" &&
                  element.preference === "veg" &&
                  element.city === "gurgaon"
                );
              }),
            });
          }
        } else {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "gurgaon"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "gurgaon"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "gurgaon"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.city === "gurgaon" &&
                  element.preference === "veg" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian")
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.preference === "veg" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "gurgaon"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "gurgaon"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" &&
                  element.preference === "veg" &&
                  element.city === "gurgaon"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.city === "gurgaon" &&
                  element.preference === "veg" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian")
                );
              }),
            });
          }
        }
      } else if (this.state.preference === "nonveg") {
        if (this.state.cuisine === "Indian & Continental") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "gurgaon"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "gurgaon"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "gurgaon"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.city === "gurgaon" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian")
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "gurgaon"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "gurgaon"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "gurgaon"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.city === "gurgaon" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian")
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Indian & PanAsian") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "gurgaon"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "gurgaon"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "gurgaon"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.city === "gurgaon"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "gurgaon"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "gurgaon"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "gurgaon"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "PanAsian") &&
                  element.city === "gurgaon"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Indian") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "gurgaon"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "gurgaon"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "gurgaon"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Indian" &&
                  element.city === "gurgaon"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "gurgaon"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "gurgaon"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "gurgaon"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Indian" &&
                  element.city === "gurgaon"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Continental") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "gurgaon"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "gurgaon"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "gurgaon"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Continental" &&
                  element.city === "gurgaon"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Continental") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "gurgaon"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "gurgaon"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "gurgaon"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Continental" &&
                  element.city === "gurgaon"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "PanAsian") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "gurgaon"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "gurgaon"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "gurgaon"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "PanAsian" &&
                  element.city === "gurgaon"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "gurgaon"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "gurgaon"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "gurgaon"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "PanAsian" &&
                  element.city === "gurgaon"
                );
              }),
            });
          }
        } else if (this.state.cuisine === "Bengali") {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "gurgaon"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "gurgaon"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Bengali") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "gurgaon"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Bengali" &&
                  element.city === "gurgaon"
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Bengali") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "gurgaon"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "gurgaon"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "gurgaon"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.cuisine === "Bengali" &&
                  element.city === "gurgaon"
                );
              }),
            });
          }
        } else {
          if (this.state.mealtype === "Starter" || "Main course") {
            this.setState({
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "gurgaon"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "gurgaon"
                );
              }),
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "gurgaon"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.city === "gurgaon" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian")
                );
              }),
            });
          } else {
            this.setState({
              mainCourse: this.state.data.filter((element) => {
                return (
                  (element.cuisine === this.state.cuisine ||
                    element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian") &&
                  element.mealtype === "Main course" &&
                  element.mealsubtype !== "Dessert" &&
                  element.mealsubtype !== "Rice" &&
                  element.mealsubtype !== "Breads" &&
                  element.city === "gurgaon"
                );
              }),
              dessert: this.state.data.filter((element) => {
                return (
                  element.mealsubtype === "Dessert" &&
                  element.city === "gurgaon"
                );
              }),
              appetizer: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Starter" && element.city === "gurgaon"
                );
              }),
              breadRice: this.state.data.filter((element) => {
                return (
                  element.mealtype === "Bread+Rice" &&
                  element.city === "gurgaon" &&
                  (element.cuisine === "Indian" ||
                    element.cuisine === "Continental" ||
                    element.cuisine === "PanAsian")
                );
              }),
            });
          }
        }
      } else {
        console.log("nonvegveg");
        // not required
      }

      // } else if (this.state.city === "gurgaon") {
      //     console.log("ghhgg")
    } else {
      console.log(this.state.city);
    }

    this.calculate();
  };

  toShowTick = (e) => {
    this.setState({
      appetizer: this.state.data.filter((element) => {
        return element.mealtype === "Starter";
      }),
    });
  };

  optionClicked(optionsList) {
    this.setState({ appetizer: optionsList });
    this.calculate();
  }

  selectedBadgeClicked(optionsList) {
    this.setState({ appetizer: optionsList });
    this.calculate();
  }

  optionClicked2(optionsList) {
    this.setState({ mainCourse: optionsList });
    this.calculate();
    console.log(optionsList);
  }

  selectedBadgeClicked2(optionsList) {
    this.setState({ mainCourse: optionsList });
    this.calculate();
    console.log(optionsList);
  }

  optionClicked3(optionsList) {
    this.setState({ dessert: optionsList });
    this.calculate();
    console.log(optionsList);
  }

  selectedBadgeClicked3(optionsList) {
    this.setState({ dessert: optionsList });
    this.calculate();
    console.log(optionsList);
  }

  optionClicked4(optionsList) {
    this.setState({ breadRice: optionsList });
    this.calculate();
    console.log(optionsList);
  }

  selectedBadgeClicked4(optionsList) {
    this.setState({ breadRice: optionsList });
    this.calculate();
    console.log(optionsList);
  }

  convertDate(date) {
    var datearray = date.split("-");
    var newdate = datearray[2] + "-" + datearray[1] + "-" + datearray[0];
    return newdate;
  }

  // renderGroupByOptions() {
  //     const { isObject = false, displayValue, showCheckbox, style, singleSelect } = this.props;
  //     const { groupedObject } = this.state;
  //     return Object.keys(groupedObject).map(obj => {
  //         return (
  //             <React.Fragment key={obj}>
  //                 <li className="groupHeading" style={style['groupHeading']}>{obj}</li>
  //                 {groupedObject[obj].map((option, i) => (
  //                     <li
  //                         key={`option${i}`}
  //                         style={style['option']}
  //                         className={`
  //                groupChildEle ${this.fadeOutSelection(option) && 'disableSelection'}
  //                 ${this.isDisablePreSelectedValues(option) && 'disableSelection'} option
  //               `}
  //                         onClick={() => this.onSelectItem(option)}
  //                     >
  //                         {showCheckbox && !singleSelect && (
  //                             <input
  //                                 type="checkbox"
  //                                 className={'checkbox'}
  //                                 readOnly
  //                                 checked={this.isSelectedValue(option)}
  //                             />
  //                         )}
  //                         {isObject ? option[displayValue] : (option || '').toString()}
  //                     </li>
  //                 ))}
  //             </React.Fragment>
  //         )
  //     });
  // }

  formSubmit = (e) => {
    e.preventDefault();

    let data = {
      name: this.state.name,
      email: this.state.email,
      mobileno: this.state.mobileno,
      city: this.state.city,
      occasion: this.state.occasion,
      people: this.state.people,
      date: this.state.date,
      url: this.state.url,

      meal: this.state.meal,
      cuisine: this.state.cuisine,
      preference: this.state.preference,
      mealtype: this.state.mealtype,
      boolean: true,
      data: [newdata.Sheet1],

      appetizer: this.state.appetizer,
      mainCourse: this.state.mainCourse,
      dessert: this.state.dessert,
      breadRice: this.state.breadRice,
      grandTotal: this.state.grandTotal,
      buffet: this.state.buffet,
      dessertClassname: "caterNinja_add_dessert_button",
      showDessert: false,
      totalMainCoursePrice: this.state.totalMainCoursePrice,
      totalAppeticerPrice: this.state.totalAppeticerPrice,
      totalDessertPrice: this.state.totalDessertPrice,
      totalBreadRicePrice: this.state.totalBreadRicePrice,

      // appetizer:this.state.appetizer,
      // mainCourse:this.state.mainCourse,
      // breadRice:this.state.breadRice,
      // dessert:this.state.dessert
    };

    axios
      .post("/api/forma", data)
      .then((res) => {
        this.setState(
          {
            sent: true,
          },
          this.resetForm()
        );
      })
      .catch((error) => {
        console.log("message not sent");
        console.log(error);
      });
  };

  formSubmitB = (e) => {
    // e.preventDefault();

    let data = {
      name: this.state.name,
      email: this.state.email,
      mobileno: this.state.mobileno,
      city: this.state.city,
      occasion: this.state.occasion,
      people: this.state.people,
      date: this.state.date,

      meal: this.state.meal,
      cuisine: this.state.cuisine,
      preference: this.state.preference,
      mealtype: this.state.mealtype,
      boolean: true,
      data: [newdata.Sheet1],

      appetizer: this.state.appetizer,
      mainCourse: this.state.mainCourse,
      dessert: this.state.dessert,
      breadRice: this.state.breadRice,
      grandTotal: this.state.grandTotal,
      buffet: this.state.buffet,
      dessertClassname: "caterNinja_add_dessert_button",
      showDessert: false,
      totalMainCoursePrice: this.state.totalMainCoursePrice,
      totalAppeticerPrice: this.state.totalAppeticerPrice,
      totalDessertPrice: this.state.totalDessertPrice,
      totalBreadRicePrice: this.state.totalBreadRicePrice,

      // appetizer:this.state.appetizer,
      // mainCourse:this.state.mainCourse,
      // breadRice:this.state.breadRice,
      // dessert:this.state.dessert
    };
    if (this.state.sent === false) {
      axios
        .post("/api/formb", data)
        .then((res) => {
          this.setState(
            {
              sent: true,
            },
            this.resetForm()
          );
        })
        .catch(() => {
          console.log("message not sent");
        });
    }
    // this.setState({sent : true})
  };

  resetForm = () => {
    this.setState({
      boolean: true,
      sent: false,
      msg: true,
      // grandTotal:0,
      // totalMainCoursePrice: 0,
      // totalAppeticerPrice: 0,
      // totalDessertPrice: 0,
      // totalBreadRicePrice: 0
    });

    setTimeout(() => {
      this.setState({
        sent: false,
        msg: false,
      });
    }, 500000);
  };
  // GPAY CODE

  //   supportedInstruments = [
  //     {
  //       supportedMethods: ['https://tez.google.com/pay'],
  //       data: {
  //         pa: 'caterninjamum@icici',
  //         pn: 'CaterNinja',
  //         tr: '1234ABCD',  // your custom transaction reference ID
  //         url: 'https://caterninja-diy.herokuapp.com/',
  //         mc: '1234', // your merchant category code
  //         tn: 'Purchase in Merchant',
  //         gstBrkUp: 'GST:16.90|CGST:08.45|SGST:08.45', // GST value break up
  //         invoiceNo: 'BillRef123', // your invoice number
  //         invoiceDate: '2019-06-11T13:21:50+05:30', // your invoice date and time
  //         gstIn: '29ABCDE1234F2Z5', // your GSTIN
  //       },
  //     }
  //   ];

  // onGooglePayLoaded(){
  //     let googlePayClient;

  //     googlePayClient = new google.payments.api.PaymentsClient({
  //         environment
  //     })
  // }

  // new
  // buttonChange(){
  // if(this.state.city==="")
  // }

  render() {
    const selectedOptionsStyles = {
      color: "rgb(0 0 0)",
      backgroundColor: "#ffeb3b",
      padding: "7px 30px",
      borderRadius: "10px",
    };
    const optionsListStyles = {
      backgroundColor: "#dff0d8",
      color: "#3c763d",
      appetizerPrice: "",
    };

    return (
      <div>
        <div
          className="d-flex align-items-center justify-content-center top-div"
          style={{ height: "350px" }}
        >
          <Button
            className="herobtn"
            variant="primary"
            onClick={this.openModal}
          >
            <h4 style={{ fontWeight: "bold" }}>Get Instant Quote</h4>
          </Button>
          <img
            style={{
              position: "absolute",
              top: "17.2rem",
            }}
            src={clickMeImage}
            alt="click me"
            height={100}
          />
        </div>

        <div style={{ textAlign: "center" }} className="topDiv">
          <a href="https://www.caterninja.com/">
            <img src={images} alt="logo" className="logo" />
          </a>
          <ul className="ulol">
            <li className="lili">
              🍀 Step 1: Choose Your Own Menu and Get Quote
            </li>
            <li>🍀 Step 2: Go Back, Change Menu, Get Revised Quote</li>
            <li>🍀 Step 3: Book Instantly!</li>
          </ul>
          <em>Powered by Ai</em>
        </div>
        {/* <a id="chat" href="https://api.whatsapp.com/send?phone=917738096313&amp;text=Hey! Need help booking a DIY Menu" className="whatsapp" title="WhatsApp us" async>
                    <div id="whatP">
                        <i id="whatsicon" className="fab fa-whatsapp"></i>
                        <p id="mmm" style={{ margin: '0' }}> Need Help !! Call US</p>
                    </div>
                </a> */}
        {/* one modal  no prev nextonetotwo */}
        <Modal
          show={this.state.isOpen1}
          onHide={this.closeModal}
          animation={false}
          backdrop="static"
          dialogClassName="modal-50w"
        >
          {/* <i className="fas fa-user-ninja"></i> */}

          {this.state.city === "mumbai" && (
            <a
              id="chat"
              href="tel:+918047176666"
              className="whatsapp"
              title="WhatsApp us"
              async
            >
              <div id="whatP">
                <i id="whatsicon" className="fas fa-phone"></i>
                <p id="mmm" style={{ margin: "0" }}>
                  Need Help !! Call US
                </p>
              </div>
            </a>
          )}
          {this.state.city === "bangalore" && (
            <a
              id="chat"
              href="tel:+918047176666"
              className="whatsapp"
              title="WhatsApp us"
              async
            >
              <div id="whatP">
                <i id="whatsicon" className="fas fa-phone"></i>
                <p id="mmm" style={{ margin: "0" }}>
                  Need Help !! Call US
                </p>
              </div>
            </a>
          )}
          {this.state.city === "delhi" && (
            <a
              id="chat"
              href="tel:+918047176666"
              className="whatsapp"
              title="WhatsApp us"
              async
            >
              <div id="whatP">
                <i id="whatsicon" className="fas fa-phone"></i>
                <p id="mmm" style={{ margin: "0" }}>
                  Need Help !! Call US
                </p>
              </div>
            </a>
          )}

          {this.state.city === "gurgaon" && (
            <a
              id="chat"
              href="tel:+918047176666"
              className="whatsapp"
              title="WhatsApp us"
              async
            >
              <div id="whatP">
                <i id="whatsicon" className="fas fa-phone"></i>
                <p id="mmm" style={{ margin: "0" }}>
                  Need Help !! Call US
                </p>
              </div>
            </a>
          )}
          <div id="modal">
            <div id="boder"></div>
            <Modal.Header closeButton>
              <Modal.Title>
                <h5 id="web-h5">
                  Welcome to CaterNinja <i className="fas fa-user-ninja"></i>
                </h5>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div>
                <div style={{ textAlign: "center" }}>
                  <h6 id="h6h6">
                    NinjaBox - Bulk Food Delivery
                    <br />
                    NinjaBuffet - Buffet Setup + Service
                  </h6>
                  <a href="https://www.caterninja.com/">
                    <img src={box} alt="box" id="xob" />
                  </a>
                </div>

                <form onSubmit={this.formSubmit}>
                  <div>
                    <div className="top-div" id="city-div">
                      <h5 id="cicc">Choose City</h5>

                      <input
                        type="radio"
                        className="btn-check"
                        name="city"
                        id="city_mumbai"
                        value="mumbai"
                        autoComplete="off"
                        defaultChecked={this.state.city === "mumbai"}
                        onChange={(e) => {
                          this.change(e);
                          this.refreshChange(e);
                        }}
                        required
                      />
                      <label
                        id="city"
                        className="btn-outline-warning"
                        htmlFor="city_mumbai"
                      >
                        <img src={imageM} alt="mumbai" id="city-logo" />
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="city"
                        id="city_bangalore"
                        value="bangalore"
                        autoComplete="off"
                        defaultChecked={this.state.city === "bangalore"}
                        onChange={(e) => {
                          this.change(e);
                          this.refreshChange(e);
                        }}
                        required
                      />
                      <label
                        id="city"
                        className="btn-outline-warning"
                        htmlFor="city_bangalore"
                      >
                        <img src={imageB} alt="bangalore" id="city-logo" />
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="city"
                        id="city_delhi"
                        value="delhi"
                        autoComplete="off"
                        defaultChecked={this.state.city === "delhi"}
                        onChange={(e) => {
                          this.change(e);
                          this.refreshChange(e);
                        }}
                        required
                      />
                      <label
                        id="city"
                        className="btn-outline-warning"
                        htmlFor="city_delhi"
                      >
                        <img src={imageD} alt="delhi" id="city-logo" />
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="city"
                        id="city_gurgaon"
                        value="gurgaon"
                        autoComplete="off"
                        defaultChecked={this.state.city === "gurgaon"}
                        onChange={(e) => {
                          this.change(e);
                          this.refreshChange(e);
                        }}
                        required
                      />
                      <label
                        id="city"
                        className="btn-outline-warning"
                        htmlFor="city_gurgaon"
                      >
                        <img src={imageG} alt="gurgaon" id="city-logo" />
                      </label>
                    </div>

                    <div className="top-div" style={{ textAlign: "center" }}>
                      <h5 id="onno">Occasion</h5>
                      <div className="ddo">
                        <input
                          type="radio"
                          className="btn-check"
                          name="options-outlined"
                          id="BirthdayParty"
                          value="Birthday Party"
                          autoComplete="off"
                          defaultChecked={
                            this.state.occasion === "Birthday Party"
                          }
                          onChange={(e) => {
                            this.occasion(e);
                          }}
                          required
                        />
                        <label
                          id="ocnc"
                          className="btn-outline-warning"
                          htmlFor="BirthdayParty"
                        >
                          <i className="fas fa-birthday-cake"></i>Birthday party
                        </label>

                        <input
                          type="radio"
                          className="btn-check"
                          name="options-outlined"
                          id="HouseParty"
                          value="House Party"
                          autoComplete="off"
                          defaultChecked={this.state.occasion === "House Party"}
                          onChange={(e) => {
                            this.occasion(e);
                          }}
                          required
                        />
                        <label
                          id="ocnc"
                          className="btn-outline-warning"
                          htmlFor="HouseParty"
                        >
                          <i className="fas fa-glass-cheers"></i> House party
                        </label>

                        <input
                          type="radio"
                          className="btn-check"
                          name="options-outlined"
                          id="HouseWarming"
                          value="House Warming"
                          autoComplete="off"
                          defaultChecked={
                            this.state.occasion === "House Warming"
                          }
                          onChange={(e) => {
                            this.occasion(e);
                          }}
                          required
                        />
                        <label
                          id="ocnc"
                          className="btn-outline-warning"
                          htmlFor="HouseWarming"
                        >
                          <i className="fas fa-home"></i>House Warming
                        </label>

                        <input
                          type="radio"
                          className="btn-check"
                          name="options-outlined"
                          id="PreWedding"
                          value="Pre Wedding"
                          autoComplete="off"
                          defaultChecked={this.state.occasion === "Pre Wedding"}
                          onChange={(e) => {
                            this.occasion(e);
                          }}
                          required
                        />
                        <label
                          id="ocnc"
                          className="btn-outline-warning"
                          htmlFor="PreWedding"
                        >
                          <i className="fas fa-male">
                            <i className="fas fa-female"></i>
                          </i>
                          Pre - Wedding
                        </label>

                        <input
                          type="radio"
                          className="btn-check"
                          name="options-outlined"
                          id="OfficeParty"
                          value="Office Party"
                          autoComplete="off"
                          defaultChecked={
                            this.state.occasion === "Office Party"
                          }
                          onChange={(e) => {
                            this.occasion(e);
                          }}
                          required
                        />
                        <label
                          id="ocnc"
                          className="btn-outline-warning"
                          htmlFor="OfficeParty"
                        >
                          <i className="fas fa-briefcase"></i>Office Party
                        </label>

                        <input
                          type="radio"
                          className="btn-check"
                          name="options-outlined"
                          id="OtherOccasion"
                          value="Other Occasion"
                          autoComplete="off"
                          defaultChecked={
                            this.state.occasion === "Other Occasion"
                          }
                          onChange={(e) => {
                            this.occasion(e);
                          }}
                          required
                        />
                        <label
                          id="ocnc"
                          className="btn-outline-warning"
                          htmlFor="OtherOccasion"
                        >
                          <i className="fas fa-sun"></i>Other Occasion
                        </label>
                      </div>
                    </div>

                    <div className="top-div" id="div-top">
                      <div className="di" id="vv">
                        <h5 id="pref">Meal Type: </h5>
                        <div className="top-div">
                          <input
                            type="radio"
                            className="btn-check"
                            name="preference"
                            id="veg"
                            value="veg"
                            autoComplete="off"
                            defaultChecked={this.state.preference === "veg"}
                            onChange={(e) => {
                              this.change(e);
                              this.refreshChange(e);
                            }}
                            required
                          />
                          <label
                            id="ocn"
                            className="btn-outline-warning"
                            htmlFor="veg"
                          >
                            Veg
                          </label>

                          <input
                            type="radio"
                            className="btn-check"
                            name="preference"
                            id="nonveg"
                            value="nonveg"
                            autoComplete="off"
                            defaultChecked={this.state.preference === "nonveg"}
                            onChange={(e) => {
                              this.change(e);
                              this.refreshChange(e);
                            }}
                            required
                          />
                          <label
                            id="ocn"
                            className="btn-outline-warning"
                            htmlFor="nonveg"
                          >
                            Veg + NonVeg
                          </label>
                          {/* 
                                                    <input type="radio" className="btn-check" name="preference" id="veg&Nonveg" value="veg & nonveg"
                                                        autoComplete="off" defaultChecked={this.state.preference === "veg & nonveg"} onChange={(e) => { this.change(e) }} required />
                                                    <label id="ocn" className="btn-outline-warning" htmlFor="veg&Nonveg">Veg & NonVeg</label> */}
                        </div>
                      </div>

                      {/* <br /> (above 7 years of age)  */}
                      <div className="di" id="vv">
                        <h5 id="ppl">Guest Count: </h5>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          name="people"
                          value={this.state.people}
                          onChange={(e) => {
                            this.change(e);
                          }}
                          id="optionSelect"
                          onClick={this.optionSelect}
                          placeholder="People"
                        >
                          <option value="">People</option>

                          {/* <option value="10">10</option>
                                                    <option value="15">15</option>
                                                    <option value="20">20</option>
                                                    <option value="25">25</option>
                                                    <option value="30">30</option>
                                                    <option value="35">35</option>
                                                    <option value="40">40</option>
                                                    <option value="45">45</option>
                                                    <option value="50">50</option> */}
                        </select>
                      </div>

                      <div className="di" id="dot">
                        <h5 className="id">Event Date: </h5>
                        <div id="deet">
                          <input
                            id="det example-datetime-local-input"
                            className="form-control date"
                            type="date"
                            min={this.state.todaysDate}
                            value={this.state.date}
                            onChange={(e) => {
                              this.setState({ date: e.target.value });
                            }}
                            onClick={this.blockDiv}
                            placeholder="DD/MM/YYYY"
                            required
                          />
                        </div>
                      </div>

                      {/* {this.state.city === 'mumbai' && <MultiSelect
                                                items={this.state.data.filter((element) => {
                                                    return (
                                                        element.mealsubtype === this.state.mealsubtype &&
                                                        element.mealtype === this.state.mealtype && element.city === this.state.city
                                                    )

                                                })} */}
                      {/* 
                                            <h2>Starters</h2>
                                            <MultiSelectReact
                                                                options={this.state.mainCourse}
                                                                optionClicked={this.optionClicked2.bind(this)}
                                                                selectedBadgeClicked={this.selectedBadgeClicked2.bind(
                                                                    this
                                                                )}
                                                                selectedOptionsStyles={selectedOptionsStyles}
                                                                optionsListStyles={optionsListStyles}
                                                            /> */}
                      {/* <MultiSelect
                                                items={this.state.data.filter((element) => {
                                                    return (
                                                        element.mealtype === "Starter"
                                                    )

                                                })}
                                                
                                                onChange={this.handleChange}
                                            /> */}

                      {/* // options={this.state.mainCourse}
                                                //                 optionClicked={this.optionClicked2.bind(this)}
                                                //                 selectedBadgeClicked={this.selectedBadgeClicked2.bind( */}
                      {/* //                     this
                                                //                 )}
                                                //                 selectedOptionsStyles={selectedOptionsStyles}
                                                //                 optionsListStyles={optionsListStyles}
                                                // selectedItems={this.option} */}

                      <hr />
                    </div>
                  </div>
                  <div id="blockDiv" style={{ display: "none" }}>
                    <div>
                      {/* <h5>Select the details :</h5> */}

                      <div className="top-div" style={{ textAlign: "center" }}>
                        <div id="cuz">Cuisine</div>
                        <div className="ddo">
                          <input
                            type="radio"
                            className="btn-check"
                            name="cuisine"
                            id="Indian"
                            value="Indian"
                            autoComplete="off"
                            defaultChecked={this.state.cuisine === "Indian"}
                            onChange={(e) => {
                              this.change(e);
                              this.refreshChange(e);
                            }}
                            required
                          />
                          <label
                            id="occ"
                            className="btn-outline-warning"
                            htmlFor="Indian"
                          >
                            North Indian
                          </label>

                          {this.state.city === "bangalore" && (
                            <>
                              <input
                                type="radio"
                                className="btn-check"
                                name="cuisine"
                                id="SouthIndian"
                                value="SouthIndian"
                                autoComplete="off"
                                defaultChecked={
                                  this.state.cuisine === "SouthIndian"
                                }
                                onChange={(e) => {
                                  this.change(e);
                                  this.refreshChange(e);
                                }}
                                required
                              />
                              <label
                                id="occ"
                                className="btn-outline-warning"
                                htmlFor="SouthIndian"
                              >
                                South Indian
                              </label>
                            </>
                          )}

                          <input
                            type="radio"
                            className="btn-check"
                            name="cuisine"
                            id="PanAsian"
                            value="PanAsian"
                            autoComplete="off"
                            defaultChecked={this.state.cuisine === "PanAsian"}
                            onChange={(e) => {
                              this.change(e);
                              this.refreshChange(e);
                            }}
                            required
                          />
                          <label
                            id="occ"
                            className="btn-outline-warning"
                            htmlFor="PanAsian"
                          >
                            Pan Asian
                          </label>

                          <input
                            type="radio"
                            className="btn-check"
                            name="cuisine"
                            id="Continential"
                            value="Continental"
                            autoComplete="off"
                            defaultChecked={
                              this.state.cuisine === "Continential"
                            }
                            onChange={(e) => {
                              this.change(e);
                              this.refreshChange(e);
                            }}
                            required
                          />
                          <label
                            id="occ"
                            className="btn-outline-warning"
                            htmlFor="Continential"
                          >
                            Conti
                          </label>

                          {/* <input type="radio" className="btn-check" name="cuisine" id="SouthIndian" value="SouthIndian"
                                                        autoComplete="off" defaultChecked={this.state.cuisine === "SouthIndian"} onChange={(e) => { this.change(e) }} required />
                                                    <label id="occ" className="btn-outline-warning" htmlFor="SouthIndian">SouthIndian</label> */}
                          {this.state.city === "bangalore" && (
                            <>
                              <input
                                type="radio"
                                className="btn-check"
                                name="cuisine"
                                id="Bengali"
                                value="Bengali"
                                autoComplete="off"
                                defaultChecked={
                                  this.state.cuisine === "Bengali"
                                }
                                onChange={(e) => {
                                  this.change(e);
                                  this.refreshChange(e);
                                }}
                                required
                              />
                              <label
                                id="occ"
                                className="btn-outline-warning"
                                htmlFor="Bengali"
                              >
                                Bengali
                              </label>
                            </>
                          )}

                          {this.state.city === "mumbai" && (
                            <>
                              <input
                                type="radio"
                                className="btn-check"
                                name="cuisine"
                                id="Bengali"
                                value="Bengali"
                                autoComplete="off"
                                defaultChecked={
                                  this.state.cuisine === "Bengali"
                                }
                                onChange={(e) => {
                                  this.change(e);
                                  this.refreshChange(e);
                                }}
                                required
                              />
                              <label
                                id="occ"
                                className="btn-outline-warning"
                                htmlFor="Bengali"
                              >
                                Bengali
                              </label>
                            </>
                          )}

                          <input
                            type="radio"
                            className="btn-check"
                            name="cuisine"
                            id="Indian&PanAsian"
                            value="Indian & PanAsian"
                            autoComplete="off"
                            defaultChecked={
                              this.state.cuisine === "Indian & PanAsian"
                            }
                            onChange={(e) => {
                              this.change(e);
                              this.refreshChange(e);
                            }}
                            required
                          />
                          <label
                            id="occ"
                            className="btn-outline-warning"
                            htmlFor="Indian&PanAsian"
                          >
                            Indian+ Chinese
                          </label>

                          <input
                            type="radio"
                            className="btn-check"
                            name="cuisine"
                            id="Indian&Continential"
                            value="Indian & Continental"
                            autoComplete="off"
                            defaultChecked={
                              this.state.cuisine === "Indian & Continential"
                            }
                            onChange={(e) => {
                              this.change(e);
                              this.refreshChange(e);
                            }}
                            required
                          />
                          <label
                            id="occ"
                            className="btn-outline-warning"
                            htmlFor="Indian&Continential"
                          >
                            Indian + Conti
                          </label>

                          <input
                            type="radio"
                            className="btn-check"
                            name="cuisine"
                            id="MultiCusine"
                            value="MultiCusine"
                            autoComplete="off"
                            defaultChecked={
                              this.state.cuisine === "MultiCusine"
                            }
                            onChange={(e) => {
                              this.change(e);
                              this.refreshChange(e);
                            }}
                            required
                          />
                          <label
                            id="occ"
                            className="btn-outline-warning"
                            htmlFor="MultiCusine"
                          >
                            Multi Cuisine
                          </label>

                          {/* 
                                                    <input type="radio" className="btn-check" name="cuisine" id="PanAsian" value="PanAsian"
                                                        autoComplete="off" defaultChecked={this.state.cuisine === "PanAsian"} onChange={(e) => { this.change(e) }} required />
                                                    <label id="occ" className="btn-outline-warning" htmlFor="PanAsian">PanAsian</label>

                                                    <input type="radio" className="btn-check" name="cuisine" id="PanAsia" value="Pan Asian"
                                                        autoComplete="off" defaultChecked={this.state.cuisine === "Pan Asian"} onChange={(e) => { this.change(e) }} required />
                                                    <label id="occ" className="btn-outline-warning" htmlFor="PanAsia">Pan Asia</label> */}
                        </div>
                      </div>

                      {this.state.changedValue && (
                        <div className="top-div" id="Iwant">
                          <div id="want">I Want 🙋🏻‍♂️: </div>
                          <div className="dd">
                            {/* <input type="radio" className="btn-check" name="mealtype" id="AppetizerOnly" value="Starter"
                                                        autoComplete="off" defaultChecked={this.state.mealtype === "Starter"} onChange={(e) => { this.change(e) }} onClick={this.showDiv} required />
                                                    <label id="ocn" className="btn-outline-warning" htmlFor="AppetizerOnly">Only Starters</label> */}

                            <input
                              type="radio"
                              className="btn-check"
                              name="mealtype"
                              id="MainCourse"
                              value="Main course"
                              autoComplete="off"
                              defaultChecked={
                                this.state.mealtype === "Main course"
                              }
                              onChange={(e) => {
                                this.change(e);
                              }}
                              onClick={this.showDiv}
                              required
                            />
                            <label
                              id="ocn"
                              className="btn-outline-warning"
                              htmlFor="MainCourse"
                            >
                              Only Mains
                            </label>

                            <input
                              type="radio"
                              className="btn-check"
                              name="mealtype"
                              id="Appetizers&MainCourse"
                              value="Starter & MainCourse"
                              autoComplete="off"
                              defaultChecked={
                                this.state.mealtype === "Starter & MainCourse"
                              }
                              onChange={(e) => {
                                this.change(e);
                              }}
                              onClick={this.showDiv}
                              required
                            />
                            <label
                              id="ocn"
                              className="btn-outline-warning"
                              htmlFor="Appetizers&MainCourse"
                            >
                              Starters + Mains
                            </label>
                          </div>
                        </div>
                      )}
                    </div>

                    <hr />

                    <div>
                      {/* <h5>Add to your Dish :</h5> */}
                      {this.state.changedValue && (
                        <div>
                          {this.state.mealtype === "Starter" && (
                            <div>
                              <div>Only Starter</div>
                              <MultiSelectReact
                                options={this.state.appetizer}
                                optionClicked={this.optionClicked.bind(this)}
                                selectedBadgeClicked={this.selectedBadgeClicked.bind(
                                  this
                                )}
                                selectedOptionsStyles={selectedOptionsStyles}
                                optionsListStyles={optionsListStyles}
                              />
                            </div>
                          )}

                          {this.state.mealtype === "Main course" && (
                            <div>
                              <div>Only Main Course</div>
                              <MultiSelectReact
                                options={this.state.mainCourse}
                                optionClicked={this.optionClicked2.bind(this)}
                                selectedBadgeClicked={this.selectedBadgeClicked2.bind(
                                  this
                                )}
                                selectedOptionsStyles={selectedOptionsStyles}
                                optionsListStyles={optionsListStyles}
                              />
                            </div>
                          )}

                          {this.state.mealtype === "Starter & MainCourse" && (
                            <div>
                              <div>Starter</div>
                              <MultiSelectReact
                                options={this.state.appetizer}
                                optionClicked={this.optionClicked.bind(this)}
                                selectedBadgeClicked={this.selectedBadgeClicked.bind(
                                  this
                                )}
                                selectedOptionsStyles={selectedOptionsStyles}
                                optionsListStyles={optionsListStyles}
                              />
                            </div>
                          )}

                          {this.state.mealtype === "Starter & MainCourse" && (
                            <div>
                              <div>Main Course</div>
                              <MultiSelectReact
                                options={this.state.mainCourse}
                                optionClicked={this.optionClicked2.bind(this)}
                                selectedBadgeClicked={this.selectedBadgeClicked2.bind(
                                  this
                                )}
                                selectedOptionsStyles={selectedOptionsStyles}
                                optionsListStyles={optionsListStyles}
                              />
                            </div>
                          )}

                          {/* {this.state.mealtype === "Starter" && <div>

                                                <div>Add Breads and Rice</div>
                                                <MultiSelectReact
                                                    options={this.state.breadRice}
                                                    optionClicked={this.optionClicked4.bind(this)}
                                                    selectedBadgeClicked={this.selectedBadgeClicked4.bind(this)}
                                                    selectedOptionsStyles={selectedOptionsStyles}
                                                    optionsListStyles={optionsListStyles}

                                                />
                                            </div>} */}

                          {this.state.mealtype === "Main course" && (
                            <div>
                              <div>Breads + Rice + Noodle</div>
                              <MultiSelectReact
                                options={this.state.breadRice}
                                optionClicked={this.optionClicked4.bind(this)}
                                selectedBadgeClicked={this.selectedBadgeClicked4.bind(
                                  this
                                )}
                                selectedOptionsStyles={selectedOptionsStyles}
                                optionsListStyles={optionsListStyles}
                              />
                            </div>
                          )}

                          {this.state.mealtype === "Starter & MainCourse" && (
                            <div>
                              <div>Breads + Rice + Noodle</div>
                              <MultiSelectReact
                                options={this.state.breadRice}
                                optionClicked={this.optionClicked4.bind(this)}
                                selectedBadgeClicked={this.selectedBadgeClicked4.bind(
                                  this
                                )}
                                selectedOptionsStyles={selectedOptionsStyles}
                                optionsListStyles={optionsListStyles}
                              />
                            </div>
                          )}

                          {this.state.showDessert && (
                            <div>
                              <div>Add Desserts</div>

                              <MultiSelectReact
                                options={this.state.dessert}
                                optionClicked={this.optionClicked3.bind(this)}
                                selectedBadgeClicked={this.selectedBadgeClicked3.bind(
                                  this
                                )}
                                selectedOptionsStyles={selectedOptionsStyles}
                                optionsListStyles={optionsListStyles}
                              />
                            </div>
                          )}

                          {/* {this.state.mealtype === "Starter" &&
                                                <div className={this.state.dessertClassname} onClick={() => { this.setState({ dessertClassname: "caterNinja_add_dessert_button caterNinja_add_dessert_button_hide", showDessert: true }) }}>
                                                    Add Dessert ++
                                                </div>} */}

                          {this.state.mealtype === "Main course" && (
                            <div
                              className={this.state.dessertClassname}
                              onClick={() => {
                                this.setState({
                                  dessertClassname:
                                    "caterNinja_add_dessert_button caterNinja_add_dessert_button_hide",
                                  showDessert: true,
                                });
                              }}
                            >
                              Add Dessert++
                            </div>
                          )}

                          {this.state.mealtype === "Starter & MainCourse" && (
                            <div
                              className={this.state.dessertClassname}
                              onClick={() => {
                                this.setState({
                                  dessertClassname:
                                    "caterNinja_add_dessert_button caterNinja_add_dessert_button_hide",
                                  showDessert: true,
                                });
                              }}
                            >
                              Add Dessert++
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                    {/* <div>Meal c price : {this.state.totalMainCoursePrice}</div>
                                                         <div>Your total dessert price is : {this.state.totalBreadRicePrice}</div>

                                            <div>Grand total = ( Appetizer Total + Main Course Total + Dessert Total + Bread & Rice Total ) + 5% GST</div>
                                                <div>= ({this.state.totalAppeticerPrice}+{this.state.totalMainCoursePrice}+{this.state.totalDessertPrice}+{this.state.totalBreadRicePrice}) + {(this.state.totalAppeticerPrice + this.state.totalMainCoursePrice + this.state.totalDessertPrice + this.state.totalBreadRicePrice) * 5 / 100}</div>
                                                    <div><strong>= {this.state.totalAppeticerPrice + this.state.totalMainCoursePrice + this.state.totalDessertPrice + this.state.totalBreadRicePrice + (((this.state.totalAppeticerPrice + this.state.totalMainCoursePrice + this.state.totalDessertPrice + this.state.totalBreadRicePrice) * 5) / 100)} Rupees only</strong></div> */}

                    {/* <div style={{ display: 'inline-flex' }}> */}
                    {/* {this.state.boolean && this.state.appetizer.some(el => el.value === true) &&
                                            <div style={{ marginRight: '25px' }}> Appetizer Details :

                                                <ul style={{ padding: '0' }}>
                                                    {this.state.appetizer.map((item, index) => {
                                                        return item.value === true && (
                                                            <li key={index}>
                                                                <li>{item.name}</li>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                                <li>Total : Rs. {this.state.totalAppeticerPrice}</li>

                                            </div>} */}
                    {/* {this.state.appetizer.map((item,index)=> {return item.value===true && (<li key={index}>{item.name}</li>)})} */}

                    {/* {this.state.boolean && this.state.mainCourse.some(el => el.value === true) &&
                                            <div style={{ marginRight: '25px' }}> Main Course Details :
                                                <ul style={{ padding: '0' }}>
                                                    {this.state.mainCourse.map((item, index) => {
                                                        return item.value === true && (
                                                            <li key={index}>
                                                                <li>{item.name}</li>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                                <li>  Total : Rs. {this.state.totalMainCoursePrice}</li>

                                            </div>} */}

                    {/* {this.state.boolean && this.state.dessert.some(el => el.value === true) &&
                                            <div style={{ marginRight: '25px' }}> Desserts Details :
                                                <ul style={{ padding: '0' }}>
                                                    {this.state.dessert.map((item, index) => {
                                                        return item.value === true && (
                                                            <li key={index}>
                                                                <li>{item.name}</li>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                                <li>Total : {this.state.totalDessertPrice}</li>

                                            </div>} */}

                    {/* {this.state.boolean && this.state.breadRice.some(el => el.value === true) &&
                                            <div style={{ marginRight: '25px' }}> Bread & Rice Details :
                                                <ul style={{ padding: '0' }}>
                                                    {this.state.breadRice.map((item, index) => {
                                                        return item.value === true && (
                                                            <li key={index}>
                                                                <li>{item.name}</li>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                                <li> Total : {this.state.totalBreadRicePrice}</li>

                                            </div>} */}
                    {/* </div> */}

                    <div style={{ margin: "20px 0" }}>
                      NinjaBox or NinjaBuffet:
                      <div
                        style={{
                          display: "inline-flex",
                          alignItems: "baseline",
                        }}
                      >
                        <select
                          aria-label="Default select example"
                          className="form-select"
                          id="fontR"
                          name="buffet"
                          value={this.state.buffet}
                          onChange={this.onChangeB.bind(this)}
                        >
                          {(this.state.city === "mumbai" ||
                            this.state.city === "bangalore") &&
                          this.state.people < 26 ? (
                            <>
                              <option value="0" defaultValue>
                                Ninjabox - Delivery (Free)
                              </option>
                              <option value="3500">
                                Buffet setup + 1 waiter (+ ₹ 3,500.00)
                              </option>
                            </>
                          ) : (this.state.city === "mumbai" ||
                              this.state.city === "bangalore") &&
                            this.state.people > 25 &&
                            this.state.people < 41 ? (
                            <>
                              <option value="0" defaultValue>
                                Ninjabox - Delivery (Free)
                              </option>
                              <option value="4500">
                                Buffet setup + 2 waiter (+ ₹ 4,500.00)
                              </option>
                            </>
                          ) : (this.state.city === "mumbai" ||
                              this.state.city === "bangalore") &&
                            this.state.people > 40 &&
                            this.state.people < 61 ? (
                            <>
                              <option value="0" defaultValue>
                                Ninjabox - Delivery Only
                              </option>
                              <option value="6000">
                                Buffet setup + Service (+ ₹ 6,000.00)
                              </option>
                            </>
                          ) : (this.state.city === "mumbai" ||
                              this.state.city === "bangalore") &&
                            this.state.people > 60 &&
                            this.state.people < 100 ? (
                            <>
                              <option value="0" defaultValue>
                                Ninjabox - Delivery Only
                              </option>
                              <option value="7500">
                                Buffet setup + Service (+ ₹ 7,500.00)
                              </option>
                            </>
                          ) : null}

                          {/* ------------------------------------- */}

                          {(this.state.city === "delhi" ||
                            this.state.city === "gurgaon") &&
                          this.state.people < 26 ? (
                            <>
                              <option value="0" defaultValue>
                                Ninjabox - Bulk Food Delivery
                              </option>
                              <option value="3500">
                                Buffet setup + 1 waiter (+ ₹ 3,500.00)
                              </option>
                            </>
                          ) : (this.state.city === "delhi" ||
                              this.state.city === "gurgaon") &&
                            this.state.people > 25 &&
                            this.state.people < 41 ? (
                            <>
                              <option value="0" defaultValue>
                                Ninjabox - Bulk Food Delivery
                              </option>
                              <option value="4500">
                                Buffet setup + 2 waiter (+ ₹ 4,500.00)
                              </option>
                            </>
                          ) : (this.state.city === "delhi" ||
                              this.state.city === "gurgaon") &&
                            this.state.people > 40 &&
                            this.state.people < 61 ? (
                            <>
                              <option value="0" defaultValue>
                                Ninjabox -Bulk Food Delivery
                              </option>
                              <option value="6000">
                                Buffet setup + Service (+ ₹ 6,000.00)
                              </option>
                            </>
                          ) : (this.state.city === "delhi" ||
                              this.state.city === "gurgaon") &&
                            this.state.people > 60 &&
                            this.state.people < 100 ? (
                            <>
                              <option value="0" defaultValue>
                                Ninjabox -Bulk Food Delivery
                              </option>
                              <option value="7500">
                                Buffet setup + Service (+ ₹ 7,500.00)
                              </option>
                            </>
                          ) : null}
                        </select>
                        <p style={{ width: "24vw", textAlign: "right" }}>
                          Rs {this.state.buffet}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "inline-flex",
                          alignItems: "baseline",
                        }}
                      >
                        <div
                          style={{
                            display: "inline",
                            borderBottom: "2px solid #ccc",
                            boxShadow: "4px 4px 6px #ccc",
                            padding: "0.8rem 3.5rem",
                            fontSize: "1rem",
                          }}
                        >
                          {(this.state.city === "mumbai" ||
                            this.state.city === "bangalore") &&
                          this.state.people < 41 ? (
                            <span>Delivery Charges (free upto 10 km)</span>
                          ) : (
                            <span>Delivery Charges</span>
                          )}
                        </div>

                        {(this.state.city === "mumbai" ||
                          this.state.city === "bangalore") &&
                        this.state.people < 41 ? (
                          <>
                            <div className="freetransport">
                              <span>
                                {(this.state.city === "mumbai" ||
                                  this.state.city === "bangalore") &&
                                this.state.people < 26 ? (
                                  <>
                                    Free{" "}
                                    {localStorage.setItem("transportCost", "0")}
                                  </>
                                ) : (this.state.city === "mumbai" ||
                                    this.state.city === "bangalore") &&
                                  this.state.people > 25 &&
                                  this.state.people <= 40 ? (
                                  <>
                                    Free{" "}
                                    {localStorage.setItem("transportCost", "0")}
                                  </>
                                ) : (this.state.city === "mumbai" ||
                                    this.state.city === "bangalore") &&
                                  this.state.people >= 41 &&
                                  this.state.people <= 60 ? (
                                  <>
                                    Rs 1499
                                    {localStorage.setItem(
                                      "transportCost",
                                      "1499"
                                    )}
                                  </>
                                ) : (this.state.city === "mumbai" ||
                                    this.state.city === "bangalore") &&
                                  this.state.people >= 61 &&
                                  this.state.people <= 99 ? (
                                  <>
                                    {" "}
                                    Rs 1999{" "}
                                    {localStorage.setItem(
                                      "transportCost",
                                      "1999"
                                    )}
                                  </>
                                ) : (this.state.city === "delhi" ||
                                    this.state.city === "gurgaon") &&
                                  this.state.people < 26 ? (
                                  <>
                                    {" "}
                                    Rs 499{" "}
                                    {localStorage.setItem(
                                      "transportCost",
                                      "499"
                                    )}
                                  </>
                                ) : (this.state.city === "delhi" ||
                                    this.state.city === "gurgaon") &&
                                  this.state.people > 25 &&
                                  this.state.people < 41 ? (
                                  <>
                                    {" "}
                                    Rs 999{" "}
                                    {localStorage.setItem(
                                      "transportCost",
                                      "999"
                                    )}
                                  </>
                                ) : (this.state.city === "delhi" ||
                                    this.state.city === "gurgaon") &&
                                  this.state.people > 40 &&
                                  this.state.people < 61 ? (
                                  <>
                                    {" "}
                                    Rs 1499{" "}
                                    {localStorage.setItem(
                                      "transportCost",
                                      "1499"
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                    Rs 1999{" "}
                                    {localStorage.setItem(
                                      "transportCost",
                                      "1999"
                                    )}
                                  </>
                                )}
                              </span>
                            </div>
                          </>
                        ) : (
                          <div className="paidtransport">
                            <span>
                              {(this.state.city === "mumbai" ||
                                this.state.city === "bangalore") &&
                              this.state.people < 26 ? (
                                <>
                                  Free{" "}
                                  {localStorage.setItem("transportCost", "0")}
                                </>
                              ) : (this.state.city === "mumbai" ||
                                  this.state.city === "bangalore") &&
                                this.state.people > 25 &&
                                this.state.people <= 40 ? (
                                <>
                                  Free{" "}
                                  {localStorage.setItem("transportCost", "0")}
                                </>
                              ) : (this.state.city === "mumbai" ||
                                  this.state.city === "bangalore") &&
                                this.state.people >= 41 &&
                                this.state.people <= 60 ? (
                                <>
                                  Rs 1499
                                  {localStorage.setItem(
                                    "transportCost",
                                    "1499"
                                  )}
                                </>
                              ) : (this.state.city === "mumbai" ||
                                  this.state.city === "bangalore") &&
                                this.state.people >= 61 &&
                                this.state.people <= 99 ? (
                                <>
                                  {" "}
                                  Rs 1999{" "}
                                  {localStorage.setItem(
                                    "transportCost",
                                    "1999"
                                  )}
                                </>
                              ) : (this.state.city === "delhi" ||
                                  this.state.city === "gurgaon") &&
                                this.state.people < 26 ? (
                                <>
                                  {" "}
                                  Rs 499{" "}
                                  {localStorage.setItem("transportCost", "499")}
                                </>
                              ) : (this.state.city === "delhi" ||
                                  this.state.city === "gurgaon") &&
                                this.state.people > 25 &&
                                this.state.people < 41 ? (
                                <>
                                  {" "}
                                  Rs 999{" "}
                                  {localStorage.setItem("transportCost", "999")}
                                </>
                              ) : (this.state.city === "delhi" ||
                                  this.state.city === "gurgaon") &&
                                this.state.people > 40 &&
                                this.state.people < 61 ? (
                                <>
                                  {" "}
                                  Rs 1499{" "}
                                  {localStorage.setItem(
                                    "transportCost",
                                    "1499"
                                  )}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  Rs 1999{" "}
                                  {localStorage.setItem(
                                    "transportCost",
                                    "1999"
                                  )}
                                </>
                              )}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>

                    <div id="showDiv" style={{ display: "none" }}>
                      <hr />
                      <center>
                        <h4> Fill your details to check Bill </h4>
                      </center>
                      <div className="top-div" id="duck">
                        <div className="ni">
                          <div className="in">
                            <input
                              className="nn"
                              value={this.state.name}
                              placeholder=" Full Name"
                              name="name"
                              onChange={(e) => {
                                this.change(e);
                              }}
                              type="text"
                              required
                            ></input>
                          </div>
                        </div>

                        <div className="ni">
                          <div className="in">
                            <input
                              className="nn"
                              value={this.state.email}
                              placeholder=" Email"
                              name="email"
                              onChange={(e) => {
                                this.change(e);
                              }}
                              type="email"
                              id="email"
                              required
                            ></input>
                          </div>
                        </div>

                        <div className="ni">
                          <div className="in">
                            <input
                              className="nn"
                              value={this.state.mobileno}
                              type="tel"
                              placeholder="Whatsapp No for Quote"
                              name="mobileno"
                              onChange={(e) => {
                                this.change(e);
                              }}
                              id="mobno"
                              required
                            ></input>
                          </div>
                        </div>
                        <br />
                        <center style={{ alignSelf: "center" }}>
                          <a
                            style={{
                              backgroundColor: "orange",
                              padding: "10px 20px",
                              borderRadius: "5px",
                            }}
                            id="cckk"
                            onClick={() => {
                              this.yourDetailB();
                              // this.formSubmitB();
                            }}
                          >
                            Check Price
                          </a>
                        </center>
                        {/* <h4 id="check" onClick={this.yourDetail}>Check Price</h4> */}
                      </div>

                      {/* unless users enters details dont show prices section */}

                      {/* <div>
                                                   Grand total = ( Appetizer Total + Main Course Total + Dessert Total + Bread & Rice Total ) + 5% GST
                                               </div>
                                                <div>
                                                    = ({this.state.totalAppeticerPrice}+{this.state.totalMainCoursePrice}+{this.state.totalDessertPrice}+{this.state.totalBreadRicePrice}) + {(this.state.totalAppeticerPrice + this.state.totalMainCoursePrice + this.state.totalDessertPrice + this.state.totalBreadRicePrice) * 5 / 100}
                                                </div>
                                                 <div>       
                                                <strong>
                                                    = {this.state.totalAppeticerPrice + this.state.totalMainCoursePrice + this.state.totalDessertPrice + this.state.totalBreadRicePrice + (((this.state.totalAppeticerPrice + this.state.totalMainCoursePrice + this.state.totalDessertPrice + this.state.totalBreadRicePrice) * 5) / 100)} Rupees only
                                                </strong>
                                                </div> */}

                      <div id="yourDetail" style={{ display: "none" }}>
                        <hr />
                        <h4>Your Bill items</h4>

                        {/* <h4 id="cp">Meal c price : {this.state.totalMainCoursePrice}</h4> */}
                        {/* menu price sould not go away + decimal round + people error + people minimum 10 to 100 + show your detal section if session is true dont want user to add multiple times email + ppl click many item top down so + */}
                        <table id="tavel">
                          <tr>
                            <td id="tdtd">
                              Appetizer Total <br /> Main Course Total
                              <br /> Bread & Rice Total <br /> Dessert Total
                            </td>
                            <td id="tddt">
                              Rs {this.state.totalAppeticerPrice} <br />
                              Rs {this.state.totalMainCoursePrice}
                              <br />
                              Rs {this.state.totalBreadRicePrice} <br />
                              Rs {this.state.totalDessertPrice}
                            </td>
                          </tr>
                        </table>
                        <hr
                          style={{
                            border: "1px solid black",
                            borderStyle: "none none solid",
                            width: "auto",
                          }}
                        />
                        <table>
                          <tr>
                            <td id="tdtd">
                              <h5>Food Cost: </h5>
                            </td>
                            <td id="tddt">
                              <h5>
                                Rs{" "}
                                {this.state.totalAppeticerPrice +
                                  this.state.totalMainCoursePrice +
                                  this.state.totalDessertPrice +
                                  this.state.totalBreadRicePrice}
                              </h5>
                            </td>
                          </tr>
                        </table>

                        <table>
                          <tr>
                            <td id="tdtd">
                              <h6>Buffet Service</h6>
                            </td>
                            <td id="tddt">
                              <h6>Rs {Math.round(this.state.buffet)}</h6>
                            </td>
                          </tr>
                        </table>

                        <table>
                          <tr>
                            <td id="tdtd">
                              <h6>Transport Additional</h6>
                            </td>
                            <td id="tddt">
                              <h6>
                                Rs{" "}
                                {Math.round(
                                  localStorage.getItem("transportCost")
                                )}
                              </h6>
                            </td>
                          </tr>
                        </table>

                        <div>
                          <table>
                            <tr>
                              <td id="tdtd">
                                <h6>5% GST</h6>
                              </td>
                              <td id="tddt">
                                <h6 id="gndT">
                                  Rs{" "}
                                  {
                                    Math.round(
                                      (this.state.totalAppeticerPrice +
                                        this.state.totalMainCoursePrice +
                                        this.state.totalDessertPrice +
                                        this.state.totalBreadRicePrice +
                                        Number(this.state.buffet) +
                                        Number(
                                          localStorage.getItem("transportCost")
                                        )
                                    ) * 5 / 100)}
                                  
                                </h6>
                              </td>
                            </tr>
                          </table>
                          <hr />

                          <table>
                            <tr>
                              <td id="tdtd">
                                <h3>Grand Total: </h3>
                              </td>
                              <td id="tddt">
                                <h3 id="gndT">
                                  Rs{" "}
                                  {Math.round(
                                    ((this.state.totalAppeticerPrice +
                                      this.state.totalMainCoursePrice +
                                      this.state.totalDessertPrice +
                                      this.state.totalBreadRicePrice +
                                      Number(this.state.buffet) +
                                      Number(
                                        localStorage.getItem("transportCost")
                                      )) *
                                      5) /
                                      100 +
                                      (this.state.totalAppeticerPrice +
                                        this.state.totalMainCoursePrice +
                                        this.state.totalDessertPrice +
                                        this.state.totalBreadRicePrice +
                                        Number(this.state.buffet) +
                                        Number(
                                          localStorage.getItem("transportCost")
                                        ))
                                  )}
                                </h3>
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <button
                            className="bn"
                            type="submit"
                            onClick={() => {
                              this.yourDetail();
                              this.hideCheckPrice();
                            }}
                            id="ckck"
                          >
                            Email Final Quote
                          </button>

                          <div id="need" style={{ display: "none" }}>
                            {/* 
                                                        <a
                                                            href="https://api.whatsapp.com/send?phone=918047176666&amp;text=Hey! Need help booking a DIY Menu"
                                                            className="bn"
                                                            id="kckc"
                                                        >
                                                            Get help to Book
                                                            <i
                                                                id="whatsicon"
                                                                className="fab fa-whatsapp"
                                                                style={{ color: "rgb(37 211 102)", fontSize: '23px' }}
                                                            ></i>
                                                        </a> */}

                            <div id="gpay">
                              {/* <GooglePayButton
                                                                environment="PRODUCTION"
                                                                buttonColor="white"
                                                                buttonType="book"
                                                                buttonSizeMode="fill"
                                                                style={{
                                                                    width: 200,
                                                                    height: 50,
                                                                    margin: "10px auto",
                                                                    boxShadow: 'rgb(119 119 119) 0px 7px 6px -6px'
                                                                }}
                                                                paymentRequest={{
                                                                    apiVersion: 2,
                                                                    apiVersionMinor: 0,
                                                                    allowedPaymentMethods: [
                                                                        {
                                                                            type: "CARD",
                                                                            parameters: {
                                                                                allowedAuthMethods: [
                                                                                    "PAN_ONLY",
                                                                                    "CRYPTOGRAM_3DS",
                                                                                ],
                                                                                allowedCardNetworks: ["AMEX", "DISCOVER", "INTERAC", "JCB", "MASTERCARD", "VISA"]
                                                                            },
                                                                            tokenizationSpecification: {
                                                                                type: "PAYMENT_GATEWAY",
                                                                                parameters: {
                                                                                 "gateway": "stripe",
                                                                                 "stripe:version": "2018-10-31",
                                                                                 "stripe:publishableKey": "pk_live_51JqwERSI4eOmJZu9VUpi0Tj3hcX47ejDrTqV5uC4rsqc4lflgIcAIsP01E0kEIQcOURniiNc08RLijBH7lifC1Tc00zpXplREz"
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                    merchantInfo: {
                                                                        merchantId: "BCR2DN6T6WZPDUTI",
                                                                        merchantName: "CaterNinja",
                                                                    },
                                                                    transactionInfo: {
                                                                        totalPriceStatus: "FINAL",
                                                                        totalPriceLabel: "Total",
                                                                        totalPrice: "1",
                                                                        currencyCode: "INR",
                                                                        countryCode: "IN",
                                                                    },
                                                                }}
                                                                onLoadPaymentData={(paymentRequest) => {
                                                                    console.log("load payment data", paymentRequest);
                                                                    <div id="sentM">Payment Success</div>
                                                                }}

                                                            /> */}
                              {/* in backend infront of items display kg or pcs upto one decimal point */}

                              {this.state.city === "mumbai" && (
                                <a
                                  href="https://api.whatsapp.com/send?phone=917738096313&amp;text=Need help booking a DIY Menu in MUMBAI"
                                  className="bn"
                                  id="kckc"
                                >
                                  <i
                                    id="whatsicon"
                                    className="fab fa-whatsapp"
                                    style={{
                                      color: "rgb(37 211 102)",
                                      fontSize: "23px",
                                    }}
                                  ></i>{" "}
                                  &nbsp; Chat help to Book
                                </a>
                              )}

                              {this.state.city === "bangalore" && (
                                <a
                                  href="https://api.whatsapp.com/send?phone=917738096313&amp;text=Need help booking a DIY Menu in BANGALORE"
                                  className="bn"
                                  id="kckc"
                                >
                                  <i
                                    id="whatsicon"
                                    className="fab fa-whatsapp"
                                    style={{
                                      color: "rgb(37 211 102)",
                                      fontSize: "23px",
                                    }}
                                  ></i>{" "}
                                  &nbsp; Chat help to Book
                                </a>
                              )}

                              {this.state.city === "delhi" && (
                                <a
                                  href="https://api.whatsapp.com/send?phone=917738096313&amp;text=Need help booking a DIY Menu in DELHI"
                                  className="bn"
                                  id="kckc"
                                >
                                  <i
                                    id="whatsicon"
                                    className="fab fa-whatsapp"
                                    style={{
                                      color: "rgb(37 211 102)",
                                      fontSize: "23px",
                                    }}
                                  ></i>{" "}
                                  &nbsp; Chat help to Book
                                </a>
                              )}

                              {this.state.city === "gurgaon" && (
                                <a
                                  href="https://api.whatsapp.com/send?phone=917738096313&amp;text=Need help booking a DIY Menu in GURGAON"
                                  className="bn"
                                  id="kckc"
                                >
                                  <i
                                    id="whatsicon"
                                    className="fab fa-whatsapp"
                                    style={{
                                      color: "rgb(37 211 102)",
                                      fontSize: "23px",
                                    }}
                                  ></i>{" "}
                                  &nbsp; Chat help to Book
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    id="checkPrize"
                    style={{
                      textAlign: "center",
                      display: "none",
                      margin: "20px auto",
                    }}
                  >
                    {/* <a className="bn" onClick={this.yourDetail}
                                            id="cckk" style={{cursor:'pointer'}} >
                                            Check Price
                                        </a> */}
                  </div>

                  {/* <h1>chat of whatspp --- need help ? on above this  beow submit why choose us---super tasty food -best chefs-loved by customer and on click this --show images on click and grid in land </h1> */}
                  {/* {this.state.err=== false ? ( */}
                  {/* <div className={this.state.err ? "show" : "hide"}>Error</div> */}
                  <div className={this.state.sent ? "show" : "hide"}>
                    <div
                      className={this.state.msg ? "show" : "hide"}
                      id="sentM"
                    >
                      <h6 style={{ color: "black", margin: "0 auto" }}>
                        " Menu Quotation sent "
                      </h6>
                    </div>
                  </div>
                  {/* ): <a>no</a> } */}

                  <div>
                    {this.state.buffet === "0" && (
                      <div style={{ textAlign: "left", fontSize: "11px" }}>
                        <h4>NinjaBox - Key Details </h4>
                        <hr
                          style={{ width: "222px", margin: "5px auto 5px 0" }}
                        />
                        <p>
                          Once you book the order, someone from the team will
                          call 🤙🏼 to confirm all your details during business
                          hours 10:00AM to 8:00 PM and accommodate any changes.
                          <br />
                          <br />
                          🎯 Don’t worry, we do 100s of events daily, we have
                          got you covered <br />
                          😎 Together we will make it a successful event. <br />
                        </p>

                        <h6>What Quantity will come?</h6>
                        <p>
                          ✅ Each Starter will be 2 pcs per person <br />
                          ✅ You can add more starters after booking
                          <br />
                          ✅ Main course & Dessert sent in sufficient quantity
                          <br />
                          ✅ Don’t worry, we ensure your food doesn’t fall short
                          <br />
                          ✅ However, if you want extra food, speak to a Ninja
                          <br />
                        </p>

                        <h6>What else comes inside NinjaBox?</h6>
                        <p>
                          ✅ We send premium bio-degradable plates & cutlery
                          <br />
                          ✅ Accompaniments are complimentary
                          <br />
                          ✅ Depends on items selected(raita, chutneys, dips..)
                          <br />
                          ✅ If you need anything extra, inform the team, they
                          will help
                          <br />
                        </p>

                        <h6>What exactly is a NinjaBox?</h6>
                        <p>
                          ✅ NinjaBox is a BIGGGG Box which has 3-5 KG food
                          <br />
                          ✅ Hassle Free Handling - On Dining Table Display
                          <br />
                          ✅ No Serving Bowls or kitchen Utensils Required
                          <br />
                          ✅ Re-heat friendly container, in case you need to
                          re-heat
                          <br />
                        </p>
                      </div>
                    )}

                    {(this.state.buffet === "3500" ||
                      this.state.buffet === "4500") && (
                      <div style={{ textAlign: "left", fontSize: "11px" }}>
                        <h4>NinjaBuffet - Key Details </h4>
                        <hr
                          style={{ width: "222px", margin: "5px auto 5px 0" }}
                        />
                        <p>
                          Once you book the order, someone from the team will
                          call 🤙🏼 to confirm all your details during business
                          hours 10:00AM to 8:00 PM and accommodate any changes.
                          <br />
                          <br />
                          🎯 Don’t worry, we do 100s of events daily, we have
                          got you covered <br />
                          😎 Together we will make it a successful event. <br />
                        </p>

                        <h6>What Quantity will come?</h6>
                        <p>
                          ✅ Each Starter will be 2 pcs per person <br />
                          ✅ You can add more starters after booking <br />
                          ✅ Main course & Dessert sent in sufficient quantity
                          <br />
                          ✅ Don’t worry, we ensure your food doesn’t fall short
                          <br />
                          ✅ However, if you want extra food, speak to a Ninja
                          <br />
                        </p>

                        <h6>What all is included in NinjaBuffet?</h6>
                        <p>
                          ✅ We send premium bio-degradable plates & cutlery
                          <br />
                          ✅ Service Staff does the Setup and Clean-up
                          <br />
                          ✅ Food Warmers with chaffing dishes <br />
                          ✅ Tables to be provided by the host <br />
                          ✅ Setup doable on Dining Tables, Any Table, Kitchen
                          Top <br />
                        </p>

                        <h6>Any other T&Cs?</h6>
                        <p>
                          ✅ Service Time is 3.5 hours from service start time
                          <br />
                          ✅ Additional hours @ 600/- per person per hour <br />
                          ✅ Transportation is free up to 10KMs <br />
                          ✅ No Messy Kitchen or cleaning hassle for you <br />
                          ✅ Food comes pre-cooked from our kitchen <br />
                          ✅ Food is served hot in food warmers <br />
                          ✅ Bar services available on request <br />
                        </p>
                      </div>
                    )}

                    {(this.state.buffet === "0" ||
                      this.state.buffet === "3500" ||
                      this.state.buffet === "4500") && (
                      <div style={{ textAlign: "center" }}>
                        <h5>Why Choose US</h5>

                        <div id="chooseus">
                          <div id="city">😋 Super Tasty Food</div>
                          <div id="city">👨🏻‍🍳 Best Chef's</div>
                          <div id="city">😍 Loved by Customer</div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* <button className='nb' onClick={this.nextonetotwo}>Next</button> */}
                </form>
              </div>
            </Modal.Body>
          </div>
        </Modal>

        {/* second modal  prevtwotoone nexttwotothree*/}
        <Modal
          show={this.state.isOpen2}
          onHide={this.closeModal}
          animation={false}
          backdrop="static"
          dialogClassName="modal-50w"
        >
          <Modal.Header closeButton>
            <Modal.Title>Total Bill :</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div>
              <div>
                <div className="result_head">
                  <div>
                    Name : {this.state.name}
                    <br />
                    Email : {this.state.email}
                    <br />
                    Mobile No : {this.state.mobileno}
                  </div>
                  <div>Occasion : {this.state.occasion}</div>
                  <div>Date : {this.convertDate(this.state.date)}</div>
                </div>

                <hr />

                <div>City : {this.state.city}</div>
                <div>Guest Count : {this.state.people}</div>

                <div>Meal : {this.state.meal}</div>
                <div>cuisine : {this.state.cuisine}</div>
                <div>Preference : {this.state.preference}</div>
                <div>Meal Type : {this.state.mealtype}</div>
                <div>
                  {/* Meal c price : {this.state.totalMainCoursePrice} */}
                </div>

                <hr />

                {/* <div style={{ display: 'inline-flex' }}> */}
                {/* {this.state.boolean && this.state.appetizer.some(el => el.value === true) &&
                                        <div style={{ marginRight: '25px' }}> Appetizer Details :

                                            <ul style={{ padding: '0' }}>
                                                {this.state.appetizer.map((item, index) => {
                                                    return item.value === true && (
                                                        <li key={index}>
                                                            <li>{item.name}</li>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                            <li>Total : Rs. {this.state.totalAppeticerPrice}</li>

                                        </div>} */}

                {/* {this.state.boolean && this.state.mainCourse.some(el => el.value === true) &&
                                        <div style={{ marginRight: '25px' }}> Main Course Details :
                                            <ul style={{ padding: '0' }}>
                                                {this.state.mainCourse.map((item, index) => {
                                                    return item.value === true && (
                                                        <li key={index}>
                                                            <li>{item.name}</li>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                            <li>  Total : Rs. {this.state.totalMainCoursePrice}</li>

                                        </div>} */}

                {/* {this.state.boolean && this.state.dessert.some(el => el.value === true) &&
                                        <div style={{ marginRight: '25px' }}> Desserts Details :
                                            <ul style={{ padding: '0' }}>
                                                {this.state.dessert.map((item, index) => {
                                                    return item.value === true && (
                                                        <li key={index}>
                                                            <li>{item.name}</li>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                            <li>Total : {this.state.totalDessertPrice}</li>

                                        </div>} */}

                {/* {this.state.boolean && this.state.breadRice.some(el => el.value === true) &&
                                        <div style={{ marginRight: '25px' }}> Bread & Rice Details :
                                            <ul style={{ padding: '0' }}>
                                                {this.state.breadRice.map((item, index) => {
                                                    return item.value === true && (
                                                        <li key={index}>
                                                            <li>{item.name}</li>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                            <li> Total : {this.state.totalBreadRicePrice}</li>

                                        </div>} */}
                {/* </div> */}
              </div>
              <hr />

              <div>
                Grand total = ( Appetizer Total + Main Course Total + Dessert
                Total + Bread & Rice Total ) + 5% GST
              </div>
              <div>
                = ({this.state.totalAppeticerPrice}+
                {this.state.totalMainCoursePrice}+{this.state.totalDessertPrice}
                +{this.state.totalBreadRicePrice}) +
                {((this.state.totalAppeticerPrice +
                  this.state.totalMainCoursePrice +
                  this.state.totalDessertPrice +
                  this.state.totalBreadRicePrice) *
                  5) /
                  100}
              </div>
              <div>
                <strong>
                  =
                  {this.state.totalAppeticerPrice +
                    this.state.totalMainCoursePrice +
                    this.state.totalDessertPrice +
                    this.state.totalBreadRicePrice +
                    ((this.state.totalAppeticerPrice +
                      this.state.totalMainCoursePrice +
                      this.state.totalDessertPrice +
                      this.state.totalBreadRicePrice) *
                      5) /
                      100}
                  Rupees only
                </strong>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="total_footer">
              <Button variant="primary" onClick={this.prevtwotoone}>
                previous
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  alert("Work in process!!");
                }}
              >
                Payment
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
